import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  SelectField,
  InputField
} from '@springforcreators/propel-ui';
import get from 'lodash/get';
import states from 'utils/statesList';
import countries from 'utils/allCountriesList';
import propTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './PreorderFormModal.scss';

const schema = yup.object({
  fullname: yup.string().required('Please enter your full name'),
  email: yup.string().email('Please enter a valid email').required('Please enter a valid email'),
  street: yup.string().required('Please enter your street address'),
  street2: yup.string(),
  city: yup.string().required('Please enter your city'),
  zip: yup.string().required('Please enter your zipcode').length(5, 'Please enter a valid zipcode'),
  state: yup.string().required('Please select your state'),
  country: yup
    .string()
    .required('Please select your country')
}).required();

const PreorderFormModal = (props) => {
  const {
    handleSubmit,
    register,
    formState,
    getValues
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });

  const themeData = useSelector(state => state.themeData);
  const { content } = themeData || {};
  const { colorId, sizeId } = props;

  const onSubmit = (values) => {
    const formData = new FormData({
      colorId,
      sizeId,
      ...values
    });
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(formData).toString()
    });
  };

  return (
    <>
      <h2>{get(content, 'preorder.titleText') || 'Pre Order'}</h2>
      <form onSubmit={ handleSubmit(onSubmit) } data-netlify="true">
        <h4 className="pr_form__label">Contact info</h4>
        <InputField
          label="Email address"
          name="email"
          required={ true }
          register={ register }
          defaultValue={ getValues('email') }
          autoComplete="email"
          error={ formState?.errors?.email?.message }
        />

        <InputField
          label="Full name"
          name="fullname"
          required={ true }
          register={ register }
          defaultValue={ getValues('fullname') }
          autoComplete="given-name"
          error={ formState?.errors?.fullname?.message }
        />

        <h4 className="pr_form__label">Delivery Address</h4>

        <SelectField
          name="country"
          label="Select country"
          register={ register }
          items={ countries }
          required={ true }
          defaultValue={ getValues('country') }
          error={ formState?.errors?.country?.message }
        />

        <InputField
          label="Street address"
          name="street"
          required={ true }
          register={ register }
          defaultValue={ getValues('street') }
          autoComplete="address-line1"
          error={ formState?.errors?.street?.message }
        />

        <InputField
          label="Apt / suite / building etc (Optional)"
          name="street2"
          required={ true }
          register={ register }
          defaultValue={ getValues('street2') }
          autoComplete="address-line2"
          error={ formState?.errors?.street2?.message }
        />

        <InputField
          label="City / Town"
          name="city"
          required={ true }
          register={ register }
          defaultValue={ getValues('city') }
          autoComplete="address-level2"
          error={ formState?.errors?.city?.message }
        />

        <SelectField
          name="state"
          label="Select state"
          register={ register }
          items={ states.unitedstates }
          required={ true }
          defaultValue={ getValues('state') }
          autoComplete="address-level1"
          error={ formState?.errors?.state?.message }
        />

        <InputField
          label="Postal code"
          name="zip"
          required={ true }
          register={ register }
          defaultValue={ getValues('zip') }
          autoComplete="postal-code"
          error={ formState?.errors?.zip?.message }
        />

        <Button
          className={ `btn--icon mt2 mb1 ${get(formState, 'isValid') ? '' : 'btn--disabled'}` }
          icon="ArrowRight"
          disabled={ !formState?.isValid }
          type="submit"
        >
          Submit
        </Button>
      </form>
    </>
  );
};

const { string } = propTypes;

PreorderFormModal.propTypes = {
  colorId: string.isRequired,
  sizeId: string.isRequired
};

export default PreorderFormModal;
