/* eslint-disable camelcase */
import { usBasedBuyer, buyerFromSupportedCountry } from 'utils/localizationUtils';
import { STRIPE_KEY, USE_CHECKOUT_V2 } from '../constants';
import { getCookie } from '../utils/cookiesUtils';

const useCheckout = () => {
  const usCheckoutOnly = usBasedBuyer();
  const useNewCheckout = (getCookie('useCheckoutV2') || USE_CHECKOUT_V2) && STRIPE_KEY;
  const isSpringPayCheckout = buyerFromSupportedCountry();
  return { useNewCheckout, usCheckoutOnly, isSpringPayCheckout };
};

export default useCheckout;
