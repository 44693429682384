const countries = [
  {
    id: 'af',
    label: 'Afghanistan',
    name: 'AF',
    code: 'AFG',
    alfa2Code: 'AF'
  },
  {
    id: 'al',
    label: 'Albania',
    name: 'AL',
    code: 'ALB',
    alfa2Code: 'AL'
  },
  {
    id: 'ad',
    label: 'Andorra',
    name: 'AD',
    code: 'AND',
    alfa2Code: 'AD'
  },
  {
    id: 'ai',
    label: 'Anguilla',
    name: 'AI',
    code: 'AIA',
    alfa2Code: 'AI'
  },
  {
    id: 'ag',
    label: 'Antigua and Barbuda',
    name: 'AG',
    code: 'ATG',
    alfa2Code: 'AG'
  },
  {
    id: 'ar',
    label: 'Argentina',
    name: 'AR',
    code: 'ARG',
    alfa2Code: 'AR'
  },
  {
    id: 'am',
    label: 'Armenia',
    name: 'AM',
    code: 'ARM',
    alfa2Code: 'AM'
  },
  {
    id: 'aw',
    label: 'Aruba',
    name: 'AW',
    code: 'ABW',
    alfa2Code: 'AW'
  },
  {
    id: 'au',
    label: 'Australia',
    name: 'AU',
    code: 'AUS',
    alfa2Code: 'AU'
  },
  {
    id: 'at',
    label: 'Austria',
    name: 'AT',
    code: 'AUT',
    alfa2Code: 'AT'
  },
  {
    id: 'az',
    label: 'Azerbaijan',
    name: 'AZ',
    code: 'AZE',
    alfa2Code: 'AZ'
  },
  {
    id: 'bs',
    label: 'Bahamas',
    name: 'BS',
    code: 'BHS',
    alfa2Code: 'BS'
  },
  {
    id: 'bh',
    label: 'Bahrain',
    name: 'BH',
    code: 'BHR',
    alfa2Code: 'BH'
  },
  {
    id: 'bd',
    label: 'Bangladesh',
    name: 'BD',
    code: 'BGD',
    alfa2Code: 'BD'
  },
  {
    id: 'bb',
    label: 'Barbados',
    name: 'BB',
    code: 'BRB',
    alfa2Code: 'BB'
  },
  {
    id: 'by',
    label: 'Belarus',
    name: 'BY',
    code: 'BLR',
    alfa2Code: 'BY'
  },
  {
    id: 'be',
    label: 'Belgium',
    name: 'BE',
    code: 'BEL',
    alfa2Code: 'BE'
  },
  {
    id: 'bz',
    label: 'Belize',
    name: 'BZ',
    code: 'BLZ',
    alfa2Code: 'BZ'
  },
  {
    id: 'bm',
    label: 'Bermuda',
    name: 'BM',
    code: 'BMU',
    alfa2Code: 'BM'
  },
  {
    id: 'bt',
    label: 'Bhutan',
    name: 'BT',
    code: 'BTN',
    alfa2Code: 'BT'
  },
  {
    id: 'bo',
    label: 'Bolivia',
    name: 'BO',
    code: 'BOL',
    alfa2Code: 'BO'
  },
  {
    id: 'ba',
    label: 'Bosnia',
    name: 'BA',
    code: 'BIH',
    alfa2Code: 'BA'
  },
  {
    id: 'br',
    label: 'Brazil',
    name: 'BR',
    code: 'BRA',
    alfa2Code: 'BR'
  },
  {
    id: 'vg',
    label: 'British Virgin Islands',
    name: 'VG',
    code: 'VGB',
    alfa2Code: 'VG'
  },
  {
    id: 'bg',
    label: 'Bulgaria',
    name: 'BG',
    code: 'BGR',
    alfa2Code: 'BG'
  },
  {
    id: 'kh',
    label: 'Cambodia',
    name: 'KH',
    code: 'KHM',
    alfa2Code: 'KH'
  },
  {
    id: 'cm',
    label: 'Cameroon',
    name: 'CM',
    code: 'CMR',
    alfa2Code: 'CM'
  },
  {
    id: 'ca',
    label: 'Canada',
    name: 'CA',
    code: 'CAN',
    alfa2Code: 'CA'
  },
  {
    id: 'ky',
    label: 'Cayman Islands',
    name: 'KY',
    code: 'CYM',
    alfa2Code: 'KY'
  },
  {
    id: 'cl',
    label: 'Chile',
    name: 'CL',
    code: 'CHL',
    alfa2Code: 'CL'
  },
  {
    id: 'cn',
    label: 'China',
    name: 'CN',
    code: 'CHN',
    alfa2Code: 'CN'
  },
  {
    id: 'cx',
    label: 'Christmas Islands',
    name: 'CX',
    code: 'CXR',
    alfa2Code: 'CX'
  },
  {
    id: 'cc',
    label: 'Cocos Islands',
    name: 'CC',
    code: 'CCK',
    alfa2Code: 'CC'
  },
  {
    id: 'co',
    label: 'Colombia',
    name: 'CO',
    code: 'COL',
    alfa2Code: 'CO'
  },
  {
    id: 'ck',
    label: 'Cook Islands',
    name: 'CK',
    code: 'COK',
    alfa2Code: 'CK'
  },
  {
    id: 'cr',
    label: 'Costa Rica',
    name: 'CR',
    code: 'CRI',
    alfa2Code: 'CR'
  },
  {
    id: 'bhr',
    label: 'Croatia',
    name: 'HR',
    code: 'HRV',
    alfa2Code: 'HR'
  },
  {
    id: 'cw',
    label: 'Curacao',
    name: 'CW',
    code: 'CUW',
    alfa2Code: 'CW'
  },
  {
    id: 'cy',
    label: 'Cyprus',
    name: 'CY',
    code: 'CYP',
    alfa2Code: 'CY'
  },
  {
    id: 'cz',
    label: 'Czech Republic',
    name: 'CZ',
    code: 'CZE',
    alfa2Code: 'CZ'
  },
  {
    id: 'dk',
    label: 'Denmark',
    name: 'DK',
    code: 'DNK',
    alfa2Code: 'DK'
  },
  {
    id: 'dm',
    label: 'Dominica',
    name: 'DM',
    code: 'DMA',
    alfa2Code: 'DM'
  },
  {
    id: 'do',
    label: 'Dominican Republic',
    name: 'DO',
    code: 'DOM',
    alfa2Code: 'DO'
  },
  {
    id: 'ec',
    label: 'Ecuador',
    name: 'EC',
    code: 'ECU',
    alfa2Code: 'EC'
  },
  {
    id: 'sv',
    label: 'El Salvador',
    name: 'SV',
    code: 'SLV',
    alfa2Code: 'SV'
  },
  {
    id: 'ee',
    label: 'Estonia',
    name: 'EE',
    code: 'EST',
    alfa2Code: 'EE'
  },
  {
    id: 'fk',
    label: 'Falkland Islands',
    name: 'FK',
    code: 'FLK',
    alfa2Code: 'FK'
  },
  {
    id: 'fo',
    label: 'Faroe Islands',
    name: 'FO',
    code: 'FRO',
    alfa2Code: 'FO'
  },
  {
    id: 'fj',
    label: 'Fiji',
    name: 'FJ',
    code: 'FJI',
    alfa2Code: 'FJ'
  },
  {
    id: 'fi',
    label: 'Finland',
    name: 'FI',
    code: 'FIN',
    alfa2Code: 'FI'
  },
  {
    id: 'fr',
    label: 'France',
    name: 'FR',
    code: 'FRA',
    alfa2Code: 'FR'
  },
  {
    id: 'gf',
    label: 'French Guiana',
    name: 'GF',
    code: 'GUF',
    alfa2Code: 'GF'
  },
  {
    id: 'pf',
    label: 'French Polynesia',
    name: 'PF',
    code: 'PYF',
    alfa2Code: 'PF'
  },
  {
    id: 'ge',
    label: 'Georgia',
    name: 'GE',
    code: 'GEO',
    alfa2Code: 'GE'
  },
  {
    id: 'de',
    label: 'Germany',
    name: 'DE',
    code: 'DEU',
    alfa2Code: 'DE'
  },
  {
    id: 'gi',
    label: 'Gibraltar',
    name: 'GI',
    code: 'GIB',
    alfa2Code: 'GI'
  },
  {
    id: 'gr',
    label: 'Greece',
    name: 'GR',
    code: 'GRC',
    alfa2Code: 'GR'
  },
  {
    id: 'gl',
    label: 'Greenland',
    name: 'GL',
    code: 'GRL',
    alfa2Code: 'GL'
  },
  {
    id: 'gd',
    label: 'Grenada',
    name: 'GD',
    code: 'GRD',
    alfa2Code: 'GD'
  },
  {
    id: 'gp',
    label: 'Guadeloupe',
    name: 'GP',
    code: 'GLP',
    alfa2Code: 'GP'
  },
  {
    id: 'gt',
    label: 'Guatemala',
    name: 'GT',
    code: 'GTM',
    alfa2Code: 'GT'
  },
  {
    id: 'gy',
    label: 'Guyana',
    name: 'GY',
    code: 'GUY',
    alfa2Code: 'GY'
  },
  {
    id: 'ht',
    label: 'Haiti',
    name: 'HT',
    code: 'HTI',
    alfa2Code: 'HT'
  },
  {
    id: 'hn',
    label: 'Honduras',
    name: 'HN',
    code: 'HND',
    alfa2Code: 'HN'
  },
  {
    id: 'hk',
    label: 'Hong Kong',
    name: 'HK',
    code: 'HKG',
    alfa2Code: 'HK'
  },
  {
    id: 'hu',
    label: 'Hungary',
    name: 'HU',
    code: 'HUN',
    alfa2Code: 'HU'
  },
  {
    id: 'is',
    label: 'Iceland',
    name: 'IS',
    code: 'ISL',
    alfa2Code: 'IS'
  },
  {
    id: 'in',
    label: 'India',
    name: 'IN',
    code: 'IND',
    alfa2Code: 'IN'
  },
  {
    id: 'id',
    label: 'Indonesia',
    name: 'ID',
    code: 'IDN',
    alfa2Code: 'ID'
  },
  {
    id: 'ie',
    label: 'Ireland',
    name: 'IE',
    code: 'IRL',
    alfa2Code: 'IE'
  },
  {
    id: 'il',
    label: 'Israel',
    name: 'IL',
    code: 'ISR',
    alfa2Code: 'IL'
  },
  {
    id: 'it',
    label: 'Italy',
    name: 'IT',
    code: 'ITA',
    alfa2Code: 'IT'
  },
  {
    id: 'jm',
    label: 'Jamaica',
    name: 'JM',
    code: 'JAM',
    alfa2Code: 'JM'
  },
  {
    id: 'jp',
    label: 'Japan',
    name: 'JP',
    code: 'JPN',
    alfa2Code: 'JP'
  },
  {
    id: 'jo',
    label: 'Jordan',
    name: 'JO',
    code: 'JOR',
    alfa2Code: 'JO'
  },
  {
    id: 'kz',
    label: 'Kazakhstan',
    name: 'KZ',
    code: 'KAZ',
    alfa2Code: 'KZ'
  },
  {
    id: 'ki',
    label: 'Kiribati',
    name: 'KI',
    code: 'KIR',
    alfa2Code: 'KI'
  },
  {
    id: 'kw',
    label: 'Kuwait',
    name: 'KW',
    code: 'KWT',
    alfa2Code: 'KW'
  },
  {
    id: 'kg',
    label: 'Kyrgyzstan',
    name: 'KG',
    code: 'KGZ',
    alfa2Code: 'KG'
  },
  {
    id: 'lv',
    label: 'Latvia',
    name: 'LV',
    code: 'LVA',
    alfa2Code: 'LV'
  },
  {
    id: 'lb',
    label: 'Lebanon',
    name: 'LB',
    code: 'LBN',
    alfa2Code: 'LB'
  },
  {
    id: 'li',
    label: 'Liechtenstein',
    name: 'LI',
    code: 'LIE',
    alfa2Code: 'LI'
  },
  {
    id: 'lt',
    label: 'Lithuania',
    name: 'LT',
    code: 'LTU',
    alfa2Code: 'LT'
  },
  {
    id: 'lu',
    label: 'Luxembourg',
    name: 'LU',
    code: 'LUX',
    alfa2Code: 'LU'
  },
  {
    id: 'mo',
    label: 'Macao',
    name: 'MO',
    code: 'MAC',
    alfa2Code: 'MO'
  },
  {
    id: 'mk',
    label: 'Macedonia',
    name: 'MK',
    code: 'MKD',
    alfa2Code: 'MK'
  },
  {
    id: 'my',
    label: 'Malaysia',
    name: 'MY',
    code: 'MYS',
    alfa2Code: 'MY'
  },
  {
    id: 'mv',
    label: 'Maldives',
    name: 'MV',
    code: 'MDV',
    alfa2Code: 'MV'
  },
  {
    id: 'mt',
    label: 'Malta',
    name: 'MT',
    code: 'MLT',
    alfa2Code: 'MT'
  },
  {
    id: 'mq',
    label: 'Martinique',
    name: 'MQ',
    code: 'MTQ',
    alfa2Code: 'MQ'
  },
  {
    id: 'mx',
    label: 'Mexico',
    name: 'MX',
    code: 'MEX',
    alfa2Code: 'MX'
  },
  {
    id: 'fm',
    label: 'Micronesia',
    name: 'FM',
    code: 'FSM',
    alfa2Code: 'FM'
  },
  {
    id: 'md',
    label: 'Moldova',
    name: 'MD',
    code: 'MDA',
    alfa2Code: 'MD'
  },
  {
    id: 'mc',
    label: 'Monaco',
    name: 'MC',
    code: 'MCO',
    alfa2Code: 'MC'
  },
  {
    id: 'mn',
    label: 'Mongolia',
    name: 'MN',
    code: 'MNG',
    alfa2Code: 'MN'
  },
  {
    id: 'me',
    label: 'Montenegro',
    name: 'ME',
    code: 'MNE',
    alfa2Code: 'ME'
  },
  {
    id: 'ms',
    label: 'Montserrat',
    name: 'MS',
    code: 'MSR',
    alfa2Code: 'MS'
  },
  {
    id: 'nr',
    label: 'Nauru',
    name: 'NR',
    code: 'NRU',
    alfa2Code: 'NR'
  },
  {
    id: 'np',
    label: 'Nepal',
    name: 'NP',
    code: 'NPL',
    alfa2Code: 'NP'
  },
  {
    id: 'nl',
    label: 'Netherlands',
    name: 'NL',
    code: 'NLD',
    alfa2Code: 'NL'
  },
  {
    id: 'nc',
    label: 'New Caledonia',
    name: 'NC',
    code: 'NCL',
    alfa2Code: 'NC'
  },
  {
    id: 'nz',
    label: 'New Zealand',
    name: 'NZ',
    code: 'NZL',
    alfa2Code: 'NZ'
  },
  {
    id: 'ni',
    label: 'Nicaragua',
    name: 'NI',
    code: 'NIC',
    alfa2Code: 'NI'
  },
  {
    id: 'no',
    label: 'Norway',
    name: 'NO',
    code: 'NOR',
    alfa2Code: 'NO'
  },
  {
    id: 'om',
    label: 'Oman',
    name: 'OM',
    code: 'OMN',
    alfa2Code: 'OM'
  },
  {
    id: 'pk',
    label: 'Pakistan',
    name: 'PK',
    code: 'PAK',
    alfa2Code: 'PK'
  },
  {
    id: 'pa',
    label: 'Panama',
    name: 'PA',
    code: 'PAN',
    alfa2Code: 'PA'
  },
  {
    id: 'pg',
    label: 'Papua New Guinea',
    name: 'PG',
    code: 'PNG',
    alfa2Code: 'PG'
  },
  {
    id: 'py',
    label: 'Paraguay',
    name: 'PY',
    code: 'PRY',
    alfa2Code: 'PY'
  },
  {
    id: 'pe',
    label: 'Peru',
    name: 'PE',
    code: 'PER',
    alfa2Code: 'PE'
  },
  {
    id: 'ph',
    label: 'Philippines',
    name: 'PH',
    code: 'PHL',
    alfa2Code: 'PH'
  },
  {
    id: 'pn',
    label: 'Pitcairn Islands',
    name: 'PN',
    code: 'PCN',
    alfa2Code: 'PN'
  },
  {
    id: 'pl',
    label: 'Poland',
    name: 'PL',
    code: 'POL',
    alfa2Code: 'PL'
  },
  {
    id: 'pt',
    label: 'Portugal',
    name: 'PT',
    code: 'PRT',
    alfa2Code: 'PT'
  },
  {
    id: 'qa',
    label: 'Qatar',
    name: 'QA',
    code: 'QAT',
    alfa2Code: 'QA'
  },
  {
    id: 'ro',
    label: 'Romania',
    name: 'RO',
    code: 'ROU',
    alfa2Code: 'RO'
  },
  {
    id: 'ru',
    label: 'Russia',
    name: 'RU',
    code: 'RUS',
    alfa2Code: 'RU'
  },
  {
    id: 'ws',
    label: 'Samoa',
    name: 'WS',
    code: 'WSM',
    alfa2Code: 'WS'
  },
  {
    id: 'sa',
    label: 'Saudi Arabia',
    name: 'SA',
    code: 'SAU',
    alfa2Code: 'SA'
  },
  {
    id: 'rs',
    label: 'Serbia',
    name: 'RS',
    code: 'SRB',
    alfa2Code: 'RS'
  },
  {
    id: 'sg',
    label: 'Singapore',
    name: 'SG',
    code: 'SGP',
    alfa2Code: 'SG'
  },
  {
    id: 'sx',
    label: 'Sint Maarten',
    name: 'SX',
    code: 'SXM',
    alfa2Code: 'SX'
  },
  {
    id: 'sk',
    label: 'Slovakia',
    name: 'SK',
    code: 'SVK',
    alfa2Code: 'SK'
  },
  {
    id: 'si',
    label: 'Slovenia',
    name: 'SI',
    code: 'SVN',
    alfa2Code: 'SI'
  },
  {
    id: 'sb',
    label: 'Solomon Islands',
    name: 'SB',
    code: 'SLB',
    alfa2Code: 'SB'
  },
  {
    id: 'za',
    label: 'South Africa',
    name: 'ZA',
    code: 'ZAF',
    alfa2Code: 'ZA'
  },
  {
    id: 'kr',
    label: 'South Korea',
    name: 'KR',
    code: 'KOR',
    alfa2Code: 'KR'
  },
  {
    id: 'es',
    label: 'Spain',
    name: 'ES',
    code: 'ESP',
    alfa2Code: 'ES'
  },
  {
    id: 'lk',
    label: 'Sri Lanka',
    name: 'LK',
    code: 'LKA',
    alfa2Code: 'LK'
  },
  {
    id: 'kn',
    label: 'St. Kitts & Nevis',
    name: 'KN',
    code: 'KNA',
    alfa2Code: 'KN'
  },
  {
    id: 'lc',
    label: 'Saint Lucia',
    name: 'LC',
    code: 'LCA',
    alfa2Code: 'LC'
  },
  {
    id: 'pm',
    label: 'Saint Pierre and Miquelon',
    name: 'PM',
    code: 'SPM',
    alfa2Code: 'PM'
  },
  {
    id: 'vc',
    label: 'St. Vincent',
    name: 'VC',
    code: 'VCT',
    alfa2Code: 'VC'
  },
  {
    id: 'sr',
    label: 'Suriname',
    name: 'SR',
    code: 'SUR',
    alfa2Code: 'SR'
  },
  {
    id: 'se',
    label: 'Sweden',
    name: 'SE',
    code: 'SWE',
    alfa2Code: 'SE'
  },
  {
    id: 'ch',
    label: 'Switzerland',
    name: 'CH',
    code: 'CHE',
    alfa2Code: 'CH'
  },
  {
    id: 'tw',
    label: 'Taiwan',
    name: 'TW',
    code: 'TWN',
    alfa2Code: 'AL'
  },
  {
    id: 'tj',
    label: 'Tajikistan',
    name: 'TJ',
    code: 'TJK',
    alfa2Code: 'TJ'
  },
  {
    id: 'th',
    label: 'Thailand',
    name: 'TH',
    code: 'THA',
    alfa2Code: 'TH'
  },
  {
    id: 'to',
    label: 'Tonga',
    name: 'TO',
    code: 'TON',
    alfa2Code: 'TO'
  },
  {
    id: 'tt',
    label: 'Trinidad',
    name: 'TT',
    code: 'TTO',
    alfa2Code: 'TT'
  },
  {
    id: 'tn',
    label: 'Tunisia',
    name: 'TN',
    code: 'TUN',
    alfa2Code: 'TN'
  },
  {
    id: 'tr',
    label: 'Turkey',
    name: 'TR',
    code: 'TUR',
    alfa2Code: 'TR'
  },
  {
    id: 'tc',
    label: 'Turks and Caicos Islands',
    name: 'TC',
    code: 'TCA',
    alfa2Code: 'TC'
  },
  {
    id: 'tv',
    label: 'Tuvalu',
    name: 'TV',
    code: 'TUV',
    alfa2Code: 'TV'
  },
  {
    id: 'ua',
    label: 'Ukraine',
    name: 'UA',
    code: 'UKR',
    alfa2Code: 'UA'
  },
  {
    id: 'ae',
    label: 'United Arab Emirates',
    name: 'AE',
    code: 'ARE',
    alfa2Code: 'AE'
  },
  {
    id: 'gb',
    label: 'United Kingdom',
    name: 'GB',
    code: 'GBR',
    alfa2Code: 'GB'
  },
  {
    id: 'us',
    label: 'United States',
    name: 'US',
    code: 'USA',
    alfa2Code: 'US'
  },
  {
    id: 'uy',
    label: 'Uruguay',
    name: 'UY',
    code: 'URY',
    alfa2Code: 'UY'
  },
  {
    id: 'uz',
    label: 'Uzbekistan',
    name: 'UZ',
    code: 'UZB',
    alfa2Code: 'UZ'
  },
  {
    id: 'vu',
    label: 'Vanuatu',
    name: 'VU',
    code: 'VUT',
    alfa2Code: 'VU'
  },
  {
    id: 've',
    label: 'Venezuela',
    name: 'VE',
    code: 'VEN',
    alfa2Code: 'VE'
  },
  {
    id: 'vn',
    label: 'Vietnam',
    name: 'VN',
    code: 'VNM',
    alfa2Code: 'VN'
  }
];

export default countries;
