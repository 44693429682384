/*eslint-disable import/prefer-default-export, consistent-return*/
import CryptoJS from 'crypto-js';
import isEqual from 'lodash/isEqual';
import iso3166 from 'iso-3166-2';
import countries from 'utils/allCountriesList';
import { CHECKOUT_STATE, DECRYPT_KEY } from '../../constants';

export const isCheckoutComplete = state => [CHECKOUT_STATE.SUCCESS].includes(state);

export const isValidCustomer = (customer) => {
  const requiredFields = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    id: customer.id,
    address1: customer.address.address1,
    city: customer.address.city,
    country: customer.address.country,
    state: customer.address.state,
    zip: customer.address.zip
  };
  return Object.values(requiredFields).every(val => val.length > 1);
};

export const encrypt = value => window.btoa(CryptoJS.AES.encrypt(value, DECRYPT_KEY).toString());

export const decrypt = (value) => {
  const bytes = CryptoJS.AES.decrypt(window.atob(value), DECRYPT_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const legalizeCopy = (paymentMethod) => {
  switch (paymentMethod) {
    case 'afterpay':
      return 'Pay now with afterpay';
    case 'paypal':
      return 'PayPal';
    case 'card':
      return 'Place order';
    default:
      return 'Pay';
  }
};

export const walletCopy = (walletType) => {
  switch (walletType) {
    case 'google-pay':
      return 'Google Pay';
    case 'apple-pay':
      return 'Apple Pay';
    default:
      return walletType;
  }
};

export const getWalletIcon = wallet => ({
  'apple-pay': 'https://teespring-ass.s3.amazonaws.com/branded_stores/images/payment-icons/apple-pay-logo.png',
  'google-pay': 'https://teespring-ass.s3.amazonaws.com/branded_stores/images/payment-icons/google-pay-logo.png',
  paypal: 'https://teespring-ass.s3.amazonaws.com/branded_stores/images/payment-icons/paypal-logo.png',
  card: 'https://teespring-ass.s3.amazonaws.com/branded_stores/images/payment-icons/card-logo.png',
  afterpay: 'https://teespring-ass.s3.amazonaws.com/branded_stores/images/payment-icons/afterpay-logo.png'
}[wallet || '']);

export const getAllErrors = (formErrors) => {
  const messages = Object.values(formErrors);
  const { errorMessages } = messages.reduce(
    (acc, curr) => {
      acc.errorMessages.push(curr.message);
      return acc;
    },
    { errorMessages: [] }
  );
  return errorMessages;
};

export const sanitizeWalletType = walletType => walletType?.replace('_', '-').toLowerCase();

export const cartMatchesCheckoutQuantity = (checkout, userCart) => {
  return checkout?.lineItems && checkout.lineItems.reduce((booleanAccumulator, currentItem) => booleanAccumulator
  && isEqual(userCart[currentItem.teespringID]?.quantity, currentItem.quantity), true);
};

export const cartMatchesCheckout = (userCart, checkout) => {
  return (
    isEqual(
      Object.keys(userCart).sort(),
      checkout?.lineItems?.map(item => item.teespringID).sort()
    ) && cartMatchesCheckoutQuantity(checkout, userCart)
  );
};

export const getStateCode = (country, state) => {
  if (country === 'United States') {
    const { code } = iso3166.subdivision('USA', state);
    return code;
  }
};

/**
 * Returns an iso3166-1 alpha-3 country code
 *
 * @param {String} countryName The full country name
 * @return {String} iso3166 alpha-3 country code
 */
export const getCountryCode = (countryName) => {
  return countries.find(country => country.label === countryName).code;
};

export const usShippingSelected = customer => customer?.address?.country === 'United States';

/**
 * Returns an iso3166-1 alpha-2 country code
 *
 * @param {String} countryName The full country name
 * @return {String} iso3166 alpha-2 country code
 */
export const getAlph2CountryCode = (countryName) => {
  return countryName && countries.find(country => country.label === countryName).alfa2Code;
};
