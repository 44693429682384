import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { upsert } from 'utils/cartUtils';
import { getAlph2CountryCode } from 'utils/checkoutUtils';
import { formatMoneyObject } from 'utils/moneyUtils';
import { PAYMENT_HOST } from '../constants';

export default async (state, paymentMethodId, currentPaymentToken, walletDetails = null) => {
  const { checkout, stores } = state;
  const paymentMethod = get(walletDetails, 'paymentMethod') ?? get(checkout, 'paymentMethod');

  const billingDetails = {
    name: `${get(checkout, 'customer.firstName')} ${get(checkout, 'customer.lastName')}`,
    address: {
      line1: get(checkout, 'customer.address.address1'),
      line2: get(checkout, 'customer.address.address2'),
      city: get(checkout, 'customer.address.city'),
      state: get(checkout, 'customer.address.state'),
      country: getAlph2CountryCode(get(checkout, 'customer.address.country')),
      postal_code: get(checkout, 'customer.address.zip')
    }
  };

  const basePayload = {
    amount: formatMoneyObject('total', checkout?.costs, { amountKey: false }),
    platform: get(checkout, 'platform'),
    paymentMethod,
    checkoutId: get(checkout, 'id'),
    billingDetails,
    paymentMethodId
  };

  const shouldCreate = paymentMethod === 'paypal' || isEmpty(get(checkout, 'paymentId', ''));
  const url = `${PAYMENT_HOST}/v1/${shouldCreate ? 'initiatePayment' : 'updatePayment'}`;
  const payload = shouldCreate
    ? {
      ...basePayload,
      storeSlug: get(stores, 'slug'),
      customer: {
        id: get(checkout, 'customer.id'),
        firstName: get(checkout, 'customer.firstName'),
        lastName: get(checkout, 'customer.lastName'),
        email: get(checkout, 'customer.email')
      },
      lookupNumber: get(checkout, 'orderLookupNumber', ''),
      storeName: get(stores, 'name')
    }
    : {
      ...basePayload,
      id: get(checkout, 'paymentId') ?? get(checkout, 'payment.id'),
      paymentToken: currentPaymentToken
    };

  return upsert(url, payload, shouldCreate);
};
