import React, { useEffect } from 'react';
import { RadioGroup, Loader } from '@springforcreators/propel-ui';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import every from 'lodash/every';
import { cartMatchesCheckout } from 'utils/checkoutUtils';
import tracker from 'utils/tracking';
import usePrevious from 'hooks/usePrevious';
import propTypes from 'prop-types';
import {
  updateCheckout
} from 'redux/actions/checkout';
import DeliveryOption from './DeliveryOption';
import './CheckoutDeliveryOptions.scss';

const CheckoutDeliveryOptions = ({
  activeSteps,
  addActiveStep
}) => {
  const reduxState = useSelector(state => state, shallowEqual);
  const {
    checkout,
    deliveryOptions,
    userCart,
    localizationData
  } = reduxState;

  const deliveryOptionsAvailable = deliveryOptions.options.length > 0;

  const dispatch = useDispatch();

  const selectedDeliveryOption = checkout?.deliveryOption;

  const prevDeliveryOptionsAvailable = usePrevious(deliveryOptionsAvailable);

  useEffect(() => {
    if (deliveryOptionsAvailable && !prevDeliveryOptionsAvailable) {
      tracker.track(
        'checkout.delivery_and_payments.viewed',
        { shippingCountry: checkout?.customer?.address?.country }
      );
    }

    if (deliveryOptionsAvailable) addActiveStep('delivery', 'payment');
  }, [deliveryOptionsAvailable]);

  const selectDeliveryOption = async (deliveryOption) => {
    if (checkout?.deliveryOption !== deliveryOption) {
      const deliveryOptionData = deliveryOptions.options.find(option => option.id === deliveryOption);
      tracker.track('checkout.delivery_option.clicked', {
        deliveryOption,
        shippingCost: deliveryOptionData.price,
        currency: localizationData.buyer_currency,
        shippingSpeed: deliveryOptionData.id
      });
      await dispatch(updateCheckout({ deliveryOption }));
    }
  };

  const isShippingFree = every(deliveryOptions.options, option => option.price === 0);
  const cartDoesNotMatchCheckout = !cartMatchesCheckout(userCart, checkout);

  return (
    <div className={ `checkoutsection ${activeSteps?.includes('delivery') ? 'is-active' : ''}` }>
      <div className="checkoutsection__heading">
        <span>2</span>
        <h3>Delivery options</h3>
      </div>

      { !deliveryOptions.isFetching && !cartDoesNotMatchCheckout && !isShippingFree && deliveryOptionsAvailable && !checkout.updatingCheckout
        ? (
          <div className="checkoutsection__inner">
            <RadioGroup
              name="delivery-options"
              className={ `deliveryoptions__radiogroup ${deliveryOptions?.options?.length === 1 ? 'deliveryoptions__single' : ''} ${checkout?.updatingCheckout ? 'is-disabled' : ''}` }
              defaultCheckedId={ selectedDeliveryOption }
              items={ deliveryOptions.options.map(deliveryOption => ({
                id: deliveryOption.id,
                title: (
                  <DeliveryOption
                    { ...deliveryOption }
                    isSelected={ deliveryOption.id === selectedDeliveryOption }
                  />
                )
              })) }
              onChange={ option => selectDeliveryOption(option) }
            />
          </div>
        )
        : (cartDoesNotMatchCheckout || deliveryOptions?.isFetching || checkout.updatingCheckout) && (
          <div className="checkoutsection__inner">
            <Loader />
          </div>
        )
      }
    </div>
  );
};

const { arrayOf, string, func } = propTypes;
CheckoutDeliveryOptions.propTypes = {
  activeSteps: arrayOf(string).isRequired,
  addActiveStep: func.isRequired
};

export default CheckoutDeliveryOptions;
