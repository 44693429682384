const states = {
  unitedstates: [
    {
      id: 'ak',
      name: 'ak',
      label: 'AK',
      fullName: 'Alaska'
    },
    {
      id: 'al',
      name: 'al',
      label: 'AL',
      fullName: 'Alabama'
    },
    {
      id: 'ar',
      name: 'ar',
      label: 'AR',
      fullName: 'Arkansas'
    },
    {
      id: 'az',
      name: 'az',
      label: 'AZ',
      fullName: 'Arizona'
    },
    {
      id: 'ca',
      name: 'ca',
      label: 'CA',
      fullName: 'California'
    },
    {
      id: 'co',
      name: 'co',
      label: 'CO',
      fullName: 'Colorado'
    },
    {
      id: 'ct',
      name: 'ct',
      label: 'CT',
      fullName: 'Connecticut'
    },
    {
      id: 'de',
      name: 'de',
      label: 'DE',
      fullName: 'Delaware'
    },
    {
      id: 'dc',
      name: 'dc',
      label: 'DC',
      fullName: 'District Of Columbia'
    },
    {
      id: 'fl',
      name: 'fl',
      label: 'FL',
      fullName: 'Florida'
    },
    {
      id: 'ga',
      name: 'ga',
      label: 'GA',
      fullName: 'Georgia'
    },
    {
      id: 'hi',
      name: 'hi',
      label: 'HI',
      fullName: 'Hawaii'
    },
    {
      id: 'ia',
      name: 'ia',
      label: 'IA',
      fullName: 'Iowa'
    },
    {
      id: 'id',
      name: 'id',
      label: 'ID',
      fullName: 'Idaho'
    },
    {
      id: 'il',
      name: 'il',
      label: 'IL',
      fullName: 'Illinois'
    },
    {
      id: 'in',
      name: 'in',
      label: 'IN',
      fullName: 'Indiana'
    },
    {
      id: 'ks',
      name: 'ks',
      label: 'KS',
      fullName: 'Kansas'
    },
    {
      id: 'ky',
      name: 'ky',
      label: 'KY',
      fullName: 'Kentucky'
    },
    {
      id: 'la',
      name: 'la',
      label: 'LA',
      fullName: 'Louisiana'
    },
    {
      id: 'ma',
      name: 'ma',
      label: 'MA',
      fullName: 'Massachusetts'
    },
    {
      id: 'md',
      name: 'md',
      label: 'MD',
      fullName: 'Maryland'
    },
    {
      id: 'me',
      name: 'me',
      label: 'ME',
      fullName: 'Maine'
    },
    {
      id: 'mi',
      name: 'mi',
      label: 'MI',
      fullName: 'Michigan'
    },
    {
      id: 'mn',
      name: 'mn',
      label: 'MN',
      fullName: 'Minnesota'
    },
    {
      id: 'mo',
      name: 'mo',
      label: 'MO',
      fullName: 'Missouri'
    },
    {
      id: 'ms',
      name: 'ms',
      label: 'MS',
      fullName: 'Mississippi'
    },
    {
      id: 'mt',
      name: 'mt',
      label: 'MT',
      fullName: 'Montana'
    },
    {
      id: 'nc',
      name: 'nc',
      label: 'NC',
      fullName: 'North Carolina'
    },
    {
      id: 'nd',
      name: 'nd',
      label: 'ND',
      fullName: 'North Dakota'
    },
    {
      id: 'ne',
      name: 'ne',
      label: 'NE',
      fullName: 'Nebraska'
    },
    {
      id: 'nh',
      name: 'nh',
      label: 'NH',
      fullName: 'New Hampshire'
    },
    {
      id: 'nj',
      name: 'nj',
      label: 'NJ',
      fullName: 'New Jersey'
    },
    {
      id: 'nm',
      name: 'nm',
      label: 'NM',
      fullName: 'New Mexico'
    },
    {
      id: 'nv',
      name: 'nv',
      label: 'NV',
      fullName: 'Nevada'
    },
    {
      id: 'ny',
      name: 'ny',
      label: 'NY',
      fullName: 'New York'
    },
    {
      id: 'oh',
      name: 'oh',
      label: 'OH',
      fullName: 'Ohio'
    },
    {
      id: 'ok',
      name: 'ok',
      label: 'OK',
      fullName: 'Oklahoma'
    },
    {
      id: 'or',
      name: 'or',
      label: 'OR',
      fullName: 'Oregon'
    },
    {
      id: 'pa',
      name: 'pa',
      label: 'PA',
      fullName: 'Pennsylvania'
    },
    {
      id: 'ri',
      name: 'ri',
      label: 'RI',
      fullName: 'Rhode Island'
    },
    {
      id: 'sc',
      name: 'sc',
      label: 'SC',
      fullName: 'South Carolina'
    },
    {
      id: 'sd',
      name: 'sd',
      label: 'SD',
      fullName: 'South Dakota'
    },
    {
      id: 'tn',
      name: 'tn',
      label: 'TN',
      fullName: 'Tennessee'
    },
    {
      id: 'tx',
      name: 'tx',
      label: 'TX',
      fullName: 'Texas'
    },
    {
      id: 'ut',
      name: 'ut',
      label: 'UT',
      fullName: 'Utah'
    },
    {
      id: 'va',
      name: 'va',
      label: 'VA',
      fullName: 'Virginia'
    },
    {
      id: 'vt',
      name: 'vt',
      label: 'VT',
      fullName: 'Vermont'
    },
    {
      id: 'wa',
      name: 'wa',
      label: 'WA',
      fullName: 'Washington'
    },
    {
      id: 'wi',
      name: 'wi',
      label: 'WI',
      fullName: 'Wisconsin'
    },
    {
      id: 'wv',
      name: 'wv',
      label: 'WV',
      fullName: 'West Virginia'
    },
    {
      id: 'wy',
      name: 'wy',
      label: 'WY',
      fullName: 'Wyoming'
    },
    {
      id: 'aa',
      name: 'aa',
      label: 'AA',
      fullName: 'AA'
    },
    {
      id: 'ap',
      name: 'ap',
      label: 'AP',
      fullName: 'AP'
    },
    {
      id: 'ae',
      name: 'ae',
      label: 'AE',
      fullName: 'AE'
    },
    {
      id: 'gu',
      name: 'gu',
      label: 'GU',
      fullName: 'Guam'
    },
    {
      id: 'as',
      name: 'as',
      label: 'AS',
      fullName: 'American Samoa'
    },
    {
      id: 'mh',
      name: 'mh',
      label: 'MH',
      fullName: 'Marshall Islands'
    },
    {
      id: 'pw',
      name: 'pw',
      label: 'PW',
      fullName: 'Palau'
    },
    {
      id: 'pr',
      name: 'pr',
      label: 'PR',
      fullName: 'Puerto Rico'
    },
    {
      id: 'vi',
      name: 'vi',
      label: 'VI',
      fullName: 'Virgin Islands'
    },
    {
      id: 'mp',
      name: 'mp',
      label: 'MP',
      fullName: 'Northern Mariana Islands'
    },
    {
      id: 'fm',
      name: 'fm',
      label: 'FM',
      fullName: 'Federated States Of Micronesia'
    }
  ],
  australia: [
    {
      id: 'nsw',
      name: 'nsw',
      label: 'NSW',
      fullName: 'New South Wales'
    },
    {
      id: 'vic',
      name: 'vic',
      label: 'VIC',
      fullName: 'Victoria'
    },
    {
      id: 'qld',
      name: 'qld',
      label: 'QLD',
      fullName: 'Queensland'
    },
    {
      id: 'wa',
      name: 'wa',
      label: 'WA',
      fullName: 'Western Australia'
    },
    {
      id: 'sa',
      name: 'sa',
      label: 'SA',
      fullName: 'Southern Australia'
    },
    {
      id: 'tas',
      name: 'tas',
      label: 'TAS',
      fullName: 'Tasmania'
    },
    {
      id: 'act',
      name: 'act',
      label: 'ACT',
      fullName: 'Australian Capital Territory'
    },
    {
      id: 'nt',
      name: 'nt',
      label: 'NT',
      fullName: 'Northern Territory'
    }
  ],
  canada: [
    {
      id: 'on',
      name: 'on',
      label: 'ON',
      fullName: 'Ontario'
    },
    {
      id: 'qc',
      name: 'qc',
      label: 'QC',
      fullName: 'Quebec'
    },
    {
      id: 'ns',
      name: 'ns',
      label: 'NS',
      fullName: 'Nova Scotia'
    },
    {
      id: 'nb',
      name: 'nb',
      label: 'NB',
      fullName: 'New Brunswick'
    },
    {
      id: 'mb',
      name: 'mb',
      label: 'MB',
      fullName: 'Manitoba'
    },
    {
      id: 'bc',
      name: 'bc',
      label: 'BC',
      fullName: 'British Columbia'
    },
    {
      id: 'pe',
      name: 'pe',
      label: 'PE',
      fullName: 'Prince Edward Island'
    },
    {
      id: 'sk',
      name: 'sk',
      label: 'SK',
      fullName: 'Saskatchewan'
    },
    {
      id: 'ab',
      name: 'ab',
      label: 'AB',
      fullName: 'Alberta'
    },
    {
      id: 'nl',
      name: 'nl',
      label: 'NL',
      fullName: 'Newfoundland and Labrador'
    }
  ],
  mexico: [
    {
      id: 'ags',
      name: 'ags',
      label: 'AG',
      fullName: 'Aguascalientes'
    },
    {
      id: 'bc',
      name: 'bc',
      label: 'BC',
      fullName: 'Baja California'
    },
    {
      id: 'bcs',
      name: 'bcs',
      label: 'BS',
      fullName: 'Baja California Sur'
    },
    {
      id: 'camp',
      name: 'camp',
      label: 'CM',
      fullName: 'Campeche'
    },
    {
      id: 'chis',
      name: 'chis',
      label: 'CS',
      fullName: 'Campeche'
    },
    {
      id: 'chih',
      name: 'chih',
      label: 'CH',
      fullName: 'Chihuahua'
    },
    {
      id: 'coah',
      name: 'coah',
      label: 'CO',
      fullName: 'Coahuila'
    },
    {
      id: 'col',
      name: 'col',
      label: 'CL',
      fullName: 'Colima'
    },
    {
      id: 'cdmx',
      name: 'cdmx',
      label: 'DF',
      fullName: 'Mexico City'
    },
    {
      id: 'dgo',
      name: 'dgo',
      label: 'DG',
      fullName: 'Durango'
    },
    {
      id: 'gto',
      name: 'gto',
      label: 'GT',
      fullName: 'Guanajuato'
    },
    {
      id: 'gro',
      name: 'gro',
      label: 'GR',
      fullName: 'Guerrero'
    },
    {
      id: 'hgo',
      name: 'hgo',
      label: 'HG',
      fullName: 'Hidalgo'
    },
    {
      id: 'jal',
      name: 'jal',
      label: 'JA',
      fullName: 'Jalisco'
    },
    {
      id: 'mex',
      name: 'mex',
      label: 'EM',
      fullName: 'Mexico'
    },
    {
      id: 'mich',
      name: 'mich',
      label: 'MI',
      fullName: 'Michoacán'
    },
    {
      id: 'mor',
      name: 'mor',
      label: 'MO',
      fullName: 'Morelos'
    },
    {
      id: 'nay',
      name: 'nay',
      label: 'NA',
      fullName: 'Nayarit'
    },
    {
      id: 'nl',
      name: 'nl',
      label: 'NL',
      fullName: 'Nuevo León'
    },
    {
      id: 'oax',
      name: 'oax',
      label: 'OA',
      fullName: 'Oaxaca'
    },
    {
      id: 'pue',
      name: 'pue',
      label: 'PU',
      fullName: 'Puebla'
    },
    {
      id: 'qro',
      name: 'qro',
      label: 'QR',
      fullName: 'Querétaro'
    },
    {
      id: 'qr',
      name: 'qr',
      label: 'QR',
      fullName: 'Quintana Roo'
    },
    {
      id: 'slp',
      name: 'slp',
      label: 'SL',
      fullName: 'San Luis Potosí'
    },
    {
      id: 'sin',
      name: 'sin',
      label: 'sin',
      fullName: 'Sinaloa'
    },
    {
      id: 'son',
      name: 'son',
      label: 'SO',
      fullName: 'Sonora'
    },
    {
      id: 'tab',
      name: 'tab',
      label: 'TB',
      fullName: 'Tabasco'
    },
    {
      id: 'tamps',
      name: 'tamps',
      label: 'TM',
      fullName: 'Tamaulipas'
    },
    {
      id: 'tlax',
      name: 'tlax',
      label: 'TL',
      fullName: 'Tlaxcala'
    },
    {
      id: 'ver',
      name: 'ver',
      label: 'VE',
      fullName: 'Veracruz'
    },
    {
      id: 'yuc',
      name: 'yuc',
      label: 'YU',
      fullName: 'Yucatán'
    },
    {
      id: 'zac',
      name: 'zac',
      label: 'ZA',
      fullName: 'Zacatecas'
    }
  ]
};

states.unitedstates.sort((a, b) => {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
});

export default states;
